import axios from 'axios'
import { toast } from 'react-toastify'

axios.defaults.withCredentials = true

// returns the whole response allowing for more handling and responding to errors.
export const handleApiCall = async (path, method, data, headers) => {
  method = !method ? 'get' : method.toLowerCase()

  const url = process.env.REACT_APP_API_URL

  if (path.split('')[0] !== '/') path = `/${path}`

  headers = { 'Content-Type': 'application/json', ...headers }
  console.log('headers1', headers)
  return axios({
    url: `${url}${path}`,
    headers,
    withCredentials: true,
    method,
    data: headers?.['Content-Type']?.includes('multipart') ? data : JSON.stringify(data)
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

// returns the response body/data or null if request failed.
export const apiCall = async (path, method, data, headers) => {
  method = !method ? 'get' : method.toLowerCase()

  const url = process.env.REACT_APP_API_URL

  if (path.split('')[0] !== '/') path = `/${path}`

  headers = { 'Content-Type': 'application/json', ...headers }
  if (process.env.REACT_APP_ENV === 'dev') headers.Origin = process.env.ORIGIN
  try {
    const response = await axios({
      url: `${url}${path}`,
      headers,
      crossDomain: true,
      withCredentials: true,
      method,
      data: JSON.stringify(data)
    })
    return response.data
  } catch (error) {
    console.log('error', error)
    return error
  }
}

export const asyncObj = async (requests) => {
  const promises = []
  Object.keys(requests).forEach((r) => {
    promises.push(requests[r])
  })
  const resolved = await Promise.all(promises)
  Object.keys(requests).forEach((r, i) => {
    requests[r] = resolved[i]
  })
  return requests
}

export const asyncArr = async (requests) => {
  let promises = []
  promises = requests
  await Promise.all(promises)
  return requests
}
